// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDGHaWqrmkeraz4vT0AUx5QvVS4ZHvmitc",
  authDomain: "efilebusiness.firebaseapp.com",
  projectId: "efilebusiness",
  storageBucket: "efilebusiness.appspot.com",
  messagingSenderId: "813454095594",
  appId: "1:813454095594:web:e147126ae8744e22d85393",
  measurementId: "G-GYZJRM2PGD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Storage
const storage = getStorage(app);

export {analytics, storage}

