<template>
  <div class="m-5">
    <div v-if="!isAuthorized">
      <h3>Enter Secret Key</h3>
      <form @submit.prevent="checkSecret">
        <div class="mb-3">
          <label for="secretKey" class="form-label">Secret Key</label>
          <input
            type="password"
            class="form-control"
            id="secretKey"
            v-model="enteredSecret"
            required
          />
        </div>
        <button type="submit" class="btn btn-primary w-100">Submit</button>
      </form>
    </div>

    <div v-else>
      <div class="d-flex justify-content-between align-items-center mt-5">
        <!-- <input
          type="text"
          class="form-control w-25"
          placeholder="Search by user"
          v-model="searchText"
          @input="fetchDocuments"
        /> -->
      </div>
      <table class="table table-hover table-bordered mt-3">
        <thead class="table-dark">
          <tr>
            <th>Company Name</th>
            <th>ID Information</th>
            <th>Address</th>
            <th>Email</th>
            <th>Name</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in allCompanies" :key="document._id">
            <td>{{ document.companyName }}</td>
            <td>
              <div>ID Type: {{ document.idDocumentType }}</div>
              <div>ID Number: {{ document.idDocumentNumber }}</div>
              <div>ID Country: {{ document.idDocumentCountry }}</div>
              <div>ID State: {{ document.idDocumentState }}</div>
              <div>
                <a :href="document.idDocumentFile" target="_blank">View Document</a>
              </div>
            </td>
            <td>
              <div>Country Formation: {{ document.countryFormation }}</div>
              <div>US Address: {{ document.usAddress }}</div>
              <div>Current Address: {{ document.currentAddress }}</div>
            </td>
            <td>{{ document.email }}</td>
            <td>{{ document.firstName }} {{ document.lastName }}</td>
            <td>{{ new Date(document.createdAt).toLocaleString() }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useCompanyInfoStore } from "@/stores/companyInfo";

export default {
  data() {
    return {
      enteredSecret: "",
      correctSecret: "1234", // Replace with your actual secret key
      isAuthorized: false,
      searchText: "", // Added search text data
    };
  },
  computed: {
    ...mapState(useCompanyInfoStore, ["allCompanies"]),
  },
  created() {
    this.checkAuthorization();
  },
  methods: {
    async fetchDocuments() {
      try {
        const companyInfoStore = useCompanyInfoStore();
        await companyInfoStore.getAllCompanies(this.searchText); // Pass search text as parameter
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    },
    checkSecret() {
      if (this.enteredSecret === this.correctSecret) {
        this.setAuthorization();
        this.isAuthorized = true;
        this.fetchDocuments();
      } else {
        alert("Incorrect secret key!");
      }
    },
    checkAuthorization() {
      const storedSecret = localStorage.getItem("secretKey");
      const storedTime = localStorage.getItem("secretKeyTime");
      const currentTime = new Date().getTime();

      if (storedSecret && storedTime && (currentTime - storedTime) < 24 * 60 * 60 * 1000) {
        if (storedSecret === this.correctSecret) {
          this.isAuthorized = true;
          this.fetchDocuments();
        }
      }
    },
    setAuthorization() {
      localStorage.setItem("secretKey", this.correctSecret);
      localStorage.setItem("secretKeyTime", new Date().getTime());
    },
  },
};
</script>

<style scoped>
.table {
  margin: 20px 0;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #343a40;
  color: #ffffff;
}

.table-hover tbody tr:hover {
  background-color: #f5f5f5;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
</style>
