import axios from 'axios';
import Swal from 'sweetalert2';

const apiClient = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
});

// Function to handle GET requests
const get = async (url, params = {}) => {
  try {
    console.log('GET', url, params);
    const client = apiClient;
    const response = await client.get(url, { params });
    return response.data;
  } catch (error) {
    console.error('GET request failed:', error);
    Swal.fire({
      title: 'Error!',
      text: `GET request failed: ${error.message}`,
      icon: 'error',
      confirmButtonText: 'OK'
    });
    throw error;
  }
};

// Function to handle POST requests
const post = async (url, data) => {
  try {
    const client = apiClient;
    const response = await client.post(url, data);
    return response.data;
  } catch (error) {
    console.error('POST request failed:', error);
    Swal.fire({
      title: 'Error!',
      text: `POST request failed: ${error.message}`,
      icon: 'error',
      confirmButtonText: 'OK'
    });
    throw error;
  }
};

export default {
  get,
  post
};
