// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage';
import DocumentsTable from './components/DocumentsTable';

const routes = [
  {
    path: '/admin',
    name: 'DocumentsTable',
    component: DocumentsTable
  },
  {
    path: '/:id',
    name: 'Home',
    component: HomePage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
