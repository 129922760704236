<template>
  <div id="app" class="container mt-5">
    <VueLoader />
    <router-view/>
  </div>
</template>

<script>
import { useCompanyInfoStore } from './stores';
import VueLoader from '@/components/VueLoader.vue';


export default {
  data() {
    return {
      currentStep: 1,
      formData: {}
    };
  },
  components: {
    VueLoader
  },
  mounted() {
    this.checkRouteParam();
  },
  watch: {
    '$route.params.id': 'checkRouteParam'
  },
  methods: {
    handleNextStep(data) {
      console.log(data);
      this.formData = { ...this.formData, ...data };
      console.log(this.formData);
      this.currentStep++;
    },
    handleFormSubmit(data) {
      const companyInfoStore = useCompanyInfoStore();
      this.formData = { ...this.formData, ...data };
      // Handle form submission, e.g., send formData to server
      console.log(this.formData);
      companyInfoStore.insertCompanyInfo(this.formData); // Call the setCompanyInfo action directly
    },
    async getCompanyInfo(id) {
      const companyInfoStore = useCompanyInfoStore();
      await companyInfoStore.getCompanyInfoById(id);
    },
    async checkRouteParam() {
      const id = this.$route.params.id;
      console.log(id);
      if (id) {
        this.getCompanyInfo(id);
      }
    },
  }
};
</script>
