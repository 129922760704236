<template>
    <div class="container mt-5">
      <div v-if="currentStep === 1">
        <CompanyInfo @next-step="handleNextStep"/>
      </div>
      <div v-else-if="currentStep === 2">
        <PersonalInfo @next-step="handleNextStep"/>
      </div>
      <div v-else-if="currentStep === 3">
        <SubmitPage @form-submit="handleFormSubmit"/>
      </div>
    </div>
  </template>
  
  <script>
  import { useCompanyInfoStore } from '../stores';
  import CompanyInfo from './CompanyInfo.vue';
  import PersonalInfo from './PersonalInfo.vue';
  import SubmitPage from './SubmitPage.vue';
  
  
  export default {
    data() {
      return {
        currentStep: 1,
        formData: {}
      };
    },
    components: {
      CompanyInfo,
      PersonalInfo,
      SubmitPage
    },
    mounted() {
      this.checkRouteParam();
    },
    watch: {
      '$route.params.id': 'checkRouteParam'
    },
    methods: {
      handleNextStep(data) {
        console.log(data);
        this.formData = { ...this.formData, ...data };
        console.log(this.formData);
        this.currentStep++;
      },
      handleFormSubmit(data) {
        const companyInfoStore = useCompanyInfoStore();
        this.formData = { ...this.formData, ...data };
        // Handle form submission, e.g., send formData to server
        console.log(this.formData);
        companyInfoStore.insertCompanyInfo(this.formData); // Call the setCompanyInfo action directly
      },
      async getCompanyInfo(id) {
        const companyInfoStore = useCompanyInfoStore();
        await companyInfoStore.getCompanyInfoById(id);
      },
      async checkRouteParam() {
        const id = this.$route.params.id;
        console.log(id);
        if (id) {
          this.getCompanyInfo(id);
        }
      },
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
  }
  </style>
  