<template>
  <div>
    <h3>Company Information</h3>
    <h4>Filing cost: $100</h4>
    <form @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="companyName" class="form-label">Reporting Company Legal Name</label>
        <input type="text" class="form-control" id="companyName" v-model="formData.companyName" required>
      </div>
      <div class="mb-3">
        <label for="identificationType" class="form-label">Form of Identification (EIN, SS/ITIN)</label>
        <input type="text" class="form-control" id="identificationType" v-model="formData.identificationType" required>
      </div>
      <div class="mb-3">
        <label for="countryFormation" class="form-label">Country/Jurisdiction of Formation</label>
        <input type="text" class="form-control" id="countryFormation" v-model="formData.countryFormation" required>
      </div>
      <div class="mb-3">
        <label for="usAddress" class="form-label">US Address</label>
        <input type="text" class="form-control" id="usAddress" v-model="formData.usAddress" placeholder="Number, Street, City, Country, State, Zip Code" required>
      </div>
      <button type="submit" class="btn btn-primary w-100">Next</button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useCompanyInfoStore } from '@/stores';

export default {
  data() {
    return {
      formData: {
        companyName: '',
        identificationType: '',
        countryFormation: '',
        usAddress: ''
      }
    };
  },
  computed: {
    ...mapState(useCompanyInfoStore, ['companyInfo'])
  },
  watch: {
    companyInfo: {
      handler(newInfo) {
        this.formData = {
          companyName: newInfo.EntityName || '',
          identificationType: this.formData.identificationType, // Keep user input if any
          countryFormation: newInfo.Jurisdiction || '',
          usAddress: newInfo.EntityAddress || ''
        };
      },
      immediate: true, // Call handler immediately on component mount
      deep: true // Watch for deep changes in companyInfo object
    }
  },
  methods: {
    submitForm() {
      this.$emit('next-step', this.formData);
    }
  }
};
</script>
