<template>
  <div>
    <h3>Personal Information</h3>
    <form @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="firstName" class="form-label">First Name</label>
        <input
          type="text"
          class="form-control"
          id="firstName"
          v-model="firstName"
          required
        />
      </div>
      <div class="mb-3">
        <label for="lastName" class="form-label">Last Name</label>
        <input
          type="text"
          class="form-control"
          id="lastName"
          v-model="lastName"
          required
        />
      </div>
      <div class="mb-3">
        <label for="dob" class="form-label">Date of Birth</label>
        <input
          type="date"
          class="form-control"
          id="dob"
          v-model="dob"
          required
        />
      </div>
      <div class="mb-3">
        <label for="currentAddress" class="form-label">Current Address</label>
        <input
          type="text"
          class="form-control"
          id="currentAddress"
          v-model="currentAddress"
          required
        />
      </div>
      <div class="mb-3">
        <label for="idDocumentType" class="form-label">ID Document Type</label>
        <select
          class="form-control"
          id="idDocumentType"
          v-model="idDocumentType"
          required
        >
          <option value="stateId" selected>State Issued Driver's License</option>
          <option value="govId">Gov Issued ID</option>
          <option value="usPassport">US Passport</option>
          <option value="foreignPassport">Foreign Passport</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="idDocumentNumber" class="form-label"
          >Identification Document Number</label
        >
        <input
          type="text"
          class="form-control"
          id="idDocumentNumber"
          v-model="idDocumentNumber"
          required
        />
      </div>
      <div class="mb-3">
        <label for="idDocumentCountry" class="form-label">Country</label>
        <input
          type="text"
          class="form-control"
          id="idDocumentCountry"
          v-model="idDocumentCountry"
          required
        />
      </div>
      <div class="mb-3">
        <label for="idDocumentState" class="form-label">State</label>
        <input
          type="text"
          class="form-control"
          id="idDocumentState"
          v-model="idDocumentState"
          required
        />
      </div>
      <div class="mb-3">
        <label for="idDocumentFile" class="form-label">Upload Document</label>
        <input
          type="file"
          class="form-control"
          id="idDocumentFile"
          accept="image/*,application/pdf"
          @change="handleFileUpload"
          required
        />
      </div>
      <button type="submit" class="btn btn-primary w-100">Next</button>
    </form>
  </div>
</template>
  
<script>
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebaseConfig";
import { useLoaderStore } from '@/stores';

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      dob: "",
      currentAddress: "",
      idDocumentType: "stateId",
      idDocumentNumber: "",
      idDocumentCountry: "",
      idDocumentState: "",
      idDocumentFile: null,
      idDocumentFileName: "",
    };
  },
  methods: {
    async handleFileUpload(event) {
      const loaderStore = useLoaderStore();
      loaderStore.showLoader();
      const file = event.target.files[0];
      if (!file) return;

      // Create a storage reference
      const storageRef = ref(storage, `uploads/${file.name}`);

      try {
        // Upload the file
        const snapshot = await uploadBytes(storageRef, file);
        console.log("Uploaded a blob or file!", snapshot);

        // Get the download URL
        const downloadURL = await getDownloadURL(storageRef);
        this.idDocumentFile = downloadURL;
        console.log("file available", downloadURL);
        loaderStore.hideLoader();
      } catch (error) {
        loaderStore.hideLoader();
        console.error("Upload failed", error);
      }
    },
    async submitForm() {
      this.$emit("next-step", {
        firstName: this.firstName,
        lastName: this.lastName,
        dob: this.dob,
        currentAddress: this.currentAddress,
        idDocumentType: this.idDocumentType,
        idDocumentNumber: this.idDocumentNumber,
        idDocumentCountry: this.idDocumentCountry,
        idDocumentState: this.idDocumentState,
        idDocumentFile: this.idDocumentFile,
      });
    },
  },
};
</script>