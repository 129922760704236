// src/stores/companyInfo.js
import { defineStore } from "pinia";
import apiService from "../services/axios"; // Import default
import { useLoaderStore } from "./loader"; // Import the loader store
import Swal from "sweetalert2"; // Import SweetAlert

const { get, post } = apiService; // Destructure the methods from the default export

// API URLs
const insertAPI = "https://insert-3kwropjpla-uc.a.run.app";
const getAPI = "https://get-3kwropjpla-uc.a.run.app";

export const useCompanyInfoStore = defineStore("companyInfo", {
  state: () => ({
    companyInfo: {
      _id: "",
      EntityNumber: "",
      URL: "",
      Status: "",
      EntityName: "",
      Jurisdiction: "",
      AgentForServiceOfProcessName: "",
      RegistrationDate: "",
      EntityType: "",
      AgentForServiceOfProcessAddress: "",
      EntityAddress: "",
      EntityMailingAddress: "",
      Mobile: "",
      Website: "",
      AddedOn: "",
      State: "",
      CEOfirstName: "",
      CEOmiddlename: "",
      CEOlastname: "",
      addedInNewDB: "",
      lastAddedOn: "",
      identificationType: "", // Add identificationType here
    },
    allCompanies: [],
  }),
  actions: {
    setCompanyInfo(newInfo) {
      this.companyInfo = { ...this.companyInfo, ...newInfo };
    },
    setAllCompanies(allCompanies) {
      console.log("setAllCompanies:", allCompanies);
      this.allCompanies = [...allCompanies];
    },
    async insertCompanyInfo(companyInfo) {
      const loaderStore = useLoaderStore();
      try {
        loaderStore.showLoader();
        const response = await post(insertAPI, companyInfo);
        this.setCompanyInfo(companyInfo);
        console.log("Form submitted:", response);
        loaderStore.hideLoader();

        // Show success alert and redirect to the home page
        Swal.fire({
          title: "Success!",
          text: "Form has been successfully submitted.",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            let url1 = window.location.href;
            let domainFromURI = new URL(url1).hostname;
            let mainDomain = this.extractMainDomain(domainFromURI);

            let url = `https://${mainDomain}/product/report`;
            // location.replace(url);
            // Redirect to the home screen of the current site
            window.location.href = `${url}`;
          }
        });
      } catch (error) {
        loaderStore.hideLoader();
        console.error("Form submission failed:", error);
        throw error;
      }
    },
    extractMainDomain(domain) {
      // Split the domain by dots
      let parts = domain.split(".");

      // Basic heuristic: if the domain has more than 2 parts (e.g., subdomain.domain.com),
      // this will return the last two parts (domain.com)
      // This may not work correctly for domains like .co.uk where the effective TLD is a second-level domain
      if (parts.length > 2) {
        // Check for known SLDs (second-level domains) like .co.uk, .gov.au, etc.
        // This list can be expanded based on requirements
        const knownSLDs = [
          "co.uk",
          "gov.au",
          "com.au",
          "gov.uk",
          "ac.uk",
          "org.uk",
          "edu.au",
          "co.in",
          "com.br",
          "com",
          "in",
          "services",
        ];
        let lastTwo = parts.slice(-2).join(".");
        if (knownSLDs.includes(lastTwo)) {
          // If the last two parts form a known SLD, include the part before them
          return parts.slice(-3).join(".");
        }
        return lastTwo;
      }
      return domain;
    },
    async getCompanyInfoById(id) {
      const loaderStore = useLoaderStore();
      try {
        loaderStore.showLoader();
        const API = `https://5qgstng9il.execute-api.us-west-1.amazonaws.com/dev/searchByNumber?term=${id}&decode=true`;
        const response = await get(API, {}, false);
        this.setCompanyInfo(response);
        loaderStore.hideLoader();
        console.log("Company info retrieved:", response);
      } catch (error) {
        loaderStore.hideLoader();
        console.error("Company info retrieval failed:", error);
        throw error;
      }
    },
    async getAllCompanies(term = "") {
      const loaderStore = useLoaderStore();
      try {
        loaderStore.showLoader();
        const response = await post(getAPI, { search: term });
        console.log("All companies retrieved:", response.documents);
        this.setAllCompanies(response.documents); // Access the documents array from the response
        loaderStore.hideLoader();
      } catch (error) {
        loaderStore.hideLoader();
        console.error("All companies retrieval failed:", error);
        throw error;
      }
    },
  },
  getters: {
    getCompanyInfo: (state) => state.companyInfo,
    companies: (state) => state.allCompanies,
  },
});
