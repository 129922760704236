// src/stores/index.js
import { createPinia } from 'pinia';
import { useCompanyInfoStore } from './companyInfo';
import { useLoaderStore } from './loader';

// Export individual stores if needed
export { useCompanyInfoStore, useLoaderStore };

// Create and export the pinia instance
const pinia = createPinia();
export default pinia;
