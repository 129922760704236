<template>
  <div>
    <div v-if="loading" class="loader-overlay">
    <div class="loader"></div>
  </div>
  </div>
</template>
  
  <script>
import { useLoaderStore } from "@/stores/loader";
import { mapState } from "pinia";

export default {
  computed: {
    ...mapState(useLoaderStore, ["loading"]),
  },
};
</script>
  
  <style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
  