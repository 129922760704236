<template>
    <div>
      <h3>Submit</h3>
      <form @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input type="email" class="form-control" id="email" v-model="email" required>
        </div>
        <div class="mb-3 form-check">
          <input type="checkbox" class="form-check-input" id="legalCheck1" v-model="legalCheck1" required>
          <label class="form-check-label" for="legalCheck1">I agree to the terms and conditions.</label>
        </div>
        <div class="mb-3 form-check">
          <input type="checkbox" class="form-check-input" id="legalCheck2" v-model="legalCheck2" required>
          <label class="form-check-label" for="legalCheck2">I confirm the information provided is accurate.</label>
        </div>
        <button type="submit" class="btn btn-primary w-100">Submit</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        email: '',
        legalCheck1: false,
        legalCheck2: false
      };
    },
    methods: {
      submitForm() {
        this.$emit('form-submit', {
          email: this.email,
          legalCheck1: this.legalCheck1,
          legalCheck2: this.legalCheck2
        });
      }
    }
  };
  </script>
  