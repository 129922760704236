// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import pinia from './stores';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

const app = createApp(App);
app.use(router);  // Use the router
app.use(pinia);
app.mount('#app');
